"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const levenshtein_1 = require("./levenshtein");
exports.levenshtein = levenshtein_1.default;
exports.levenshteinDistance = levenshtein_1.levenshteinDistance;
const lcs_1 = require("./lcs");
exports.lcs = lcs_1.default;
exports.lcslen = lcs_1.lcslen;
const simhash_1 = require("./simhash");
exports.simhash = simhash_1.simhash;
exports.hammingDistance = simhash_1.hammingDistance;
exports.hammingWeight = simhash_1.hammingWeight;
exports.simhashSimilarity = simhash_1.similarity;
function similarity(x, y, type = 'lcs', options) {
    if (type === 'lcs') {
        return lcs_1.default(x, y);
    }
    if (type === 'levenshtein') {
        return levenshtein_1.default(x, y);
    }
    if (type === 'simhash') {
        return simhash_1.similarity(x, y, options);
    }
    return 0;
}
exports.default = similarity;
